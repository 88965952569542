import type { PostProps } from '../Post'
import React, { useState } from 'react'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import Collapse from '@mui/material/Collapse'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import SummaryStat from '../../SummaryStat'
import BinocularIcon from '../../icon/BinocularIcon'
import { handleTouchTap } from '../../form/formUtils'
import FormattedText from '../FormattedText'
import UncollapsedCardContent from '../UncollapsedCardContent'
import CardActions from '../CardActions'
import PostTags from '../PostTags'
import { PostHeadshot } from '../PostHeadshot'
import PinPostIcon from '../PinPostIcon'
import ScoutingContent from './ScoutingContent'
import NotKnicksQuality from './NotKnicksQuality'
import Setting from './Setting'
import PlayerList from './PlayerList'
import PlayerTier from './PlayerTier'
import { useBreakPoints, useLOCValues, usePlayerStats, useScoutingReportBoxScores } from '@/lib/hooks'

const ScoutingPost = ({
    post,
    editPostSection,
    commentSection,
    expanded,
    setExpanded,
    mobileTagLimit,
    tabletTagLimit,
    desktopTagLimit,
    maxCardContentHeight,
    canEditPost,
    isPinned = false,
}: PostProps<DTO.ScoutingPost>): JSX.Element => {
    const [hasOverflowContent, setHasOverflowContent] = useState(false)
    const { isMobile, isTablet, isLandscape, isDesktop } = useBreakPoints()
    const { data: locValues, isLoading: isLOCValuesLoading } = useLOCValues()
    const { data: boxscores, isLoading: isBoxScoreLoading } = useScoutingReportBoxScores(
        post.settings,
        post.tags,
        expanded
    )
    const playerId = post.tags.filter((t) => t.entityType === 'player').find((t) => t.entityId)?.entityId
    const gameSeason = post.settings.find((s) => s.type === 'Game')?.season
    const { data: seasonStats, isInitialLoading: isLoadingSeasonStats } = usePlayerStats(
        playerId,
        gameSeason!, // eslint-disable-line @typescript-eslint/no-non-null-assertion
        ['REGULAR', 'SHOWCASE'],
        'standard',
        {
            enabled: expanded && post.settings.some((s) => s.type === 'Game' && !!s.gameId) && !!gameSeason,
        }
    )

    const {
        level,
        type,
        tags,
        playerLists,
        locBullseye,
        locNow,
        locHigh,
        locLow,
        notKnicksQuality,
        subject,
        settings,
        athleticism,
        bbiq,
        shooter,
        shotCreator,
        quickDecisions,
        scorer,
        finisher,
        playMaker,
        rimProtector,
        teamDefender,
        individualDefense,
        pnrDefender,
        rebounder,
        competitiveness,
        offense,
        defense,
        physical,
        overall,
        offensePrimary,
        defensePrimary,
        offensePG,
        offenseSG,
        offenseSF,
        offensePF,
        offenseC,
        defensePG,
        defenseSG,
        defenseSF,
        defensePF,
        defenseC,
        playerTier,
    } = post

    return (
        <Card
            id={`card-element-${post.postID}`}
            sx={{ pageBreakInside: expanded ? 'auto' : 'avoid' }}
            variant="outlined"
        >
            <CardHeader
                sx={{
                    paddingTop: 1,
                    paddingBottom: 0,
                    '.MuiCardHeader-avatar': { xs: { marginRight: 0 }, sm: { marginRight: '16px' } },
                }}
                avatar={
                    <Stack direction="row" spacing={1}>
                        <Chip
                            size="small"
                            label={isMobile ? '' : `${level} ${type.toUpperCase()}`}
                            sx={{
                                color: 'common.white',
                                backgroundColor: 'post.scouting',
                                fontWeight: 'medium',
                                display: 'flex',
                                justifyContent: 'center',
                                '.MuiChip-label': { xs: { paddingLeft: 0 }, sm: { paddingLeft: '8px' } },
                            }}
                            avatar={<BinocularIcon style={{ position: 'relative', left: 4, color: 'white' }} />}
                        />

                        <Stack direction="row" spacing={1}>
                            <PostTags
                                tags={tags}
                                startIndex={0}
                                endIndex={isMobile ? mobileTagLimit : isTablet ? tabletTagLimit : desktopTagLimit}
                                expanded={expanded}
                                abbreviate={isMobile || isTablet}
                            />
                            {(isLandscape || isDesktop) &&
                                type === 'SCOUTING' &&
                                playerLists.map(({ name }) => <PlayerList key={name} name={name} />)}
                            {(isLandscape || isDesktop) && type === 'SCOUTING' && playerTier && (
                                <PlayerTier tier={playerTier} />
                            )}
                        </Stack>
                    </Stack>
                }
                subheader={
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            gap: 1,
                        }}
                    >
                        {notKnicksQuality && !isMobile && <NotKnicksQuality isTablet={isTablet} />}
                        <Stack direction="row" spacing={1}>
                            {(locNow || locNow === 0) && (
                                <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                                    <SummaryStat
                                        value={locValues?.find((v) => v.value === locNow)?.abbr}
                                        label="Now"
                                        height={32}
                                        minWidth={0}
                                        isLoading={isLOCValuesLoading}
                                    />
                                </Box>
                            )}
                            {(locBullseye || locBullseye === 0) && (
                                <SummaryStat
                                    value={locValues?.find((v) => v.value === locBullseye)?.abbr}
                                    label="Bull"
                                    height={32}
                                    minWidth={0}
                                    isLoading={isLOCValuesLoading}
                                />
                            )}
                        </Stack>
                        {isPinned && <PinPostIcon />}
                    </Box>
                }
                action={canEditPost && editPostSection}
            />
            {expanded && (
                <Stack direction="row" sx={{ paddingX: 2, position: 'relative', flexWrap: 'wrap', gap: 1 }}>
                    <PostTags
                        tags={tags}
                        startIndex={isMobile ? mobileTagLimit : desktopTagLimit}
                        endIndex={tags.length}
                        expanded={expanded}
                        abbreviate={isMobile || isTablet}
                    />
                </Stack>
            )}
            <Divider sx={{ paddingTop: 1 }} />
            <UncollapsedCardContent
                maxCardContentHeight={maxCardContentHeight}
                expanded={expanded}
                hasOverflowContent={hasOverflowContent}
                setHasOverflowContent={setHasOverflowContent}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignItems: 'center',
                        gap: 0.5,
                        marginBottom: 1,
                    }}
                >
                    <PostHeadshot tag={tags[0] as DTO.PlayerTag} />
                    <Typography
                        variant="h6"
                        fontSize={isMobile ? '18px' : '20px'}
                        lineHeight={isMobile ? 1.4 : 'initial'}
                    >
                        {subject}
                    </Typography>
                    <>
                        {settings.map((s) => (
                            <Setting key={s.id} {...s} />
                        ))}
                        {notKnicksQuality && isMobile && <NotKnicksQuality isTablet={isTablet} />}
                    </>
                </Box>
                <Box onTouchEnd={(e) => handleTouchTap(e, setExpanded, expanded)}>
                    {overall && <FormattedText text={overall} marginBottom={false} />}
                </Box>
            </UncollapsedCardContent>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent
                    sx={{
                        paddingTop: 1,
                        '&.MuiCardContent-root:last-child': { paddingBottom: 1 },
                    }}
                >
                    <ScoutingContent
                        athleticism={athleticism}
                        bbiq={bbiq}
                        shooter={shooter}
                        shotCreator={shotCreator}
                        quickDecisions={quickDecisions}
                        scorer={scorer}
                        finisher={finisher}
                        playMaker={playMaker}
                        rimProtector={rimProtector}
                        teamDefender={teamDefender}
                        individualDefense={individualDefense}
                        pnrDefender={pnrDefender}
                        rebounder={rebounder}
                        competitiveness={competitiveness}
                        locBullseye={locBullseye}
                        locLow={locLow}
                        locHigh={locHigh}
                        locNow={locNow}
                        offense={offense}
                        defense={defense}
                        physical={physical}
                        playerLists={playerLists}
                        boxscores={boxscores}
                        isBoxScoreLoading={isBoxScoreLoading}
                        offensePrimary={offensePrimary}
                        defensePrimary={defensePrimary}
                        offensePG={offensePG}
                        offenseSG={offenseSG}
                        offenseSF={offenseSF}
                        offensePF={offensePF}
                        offenseC={offenseC}
                        defensePG={defensePG}
                        defenseSG={defenseSG}
                        defenseSF={defenseSF}
                        defensePF={defensePF}
                        defenseC={defenseC}
                        seasonStats={seasonStats}
                        isSeasonStatsLoading={isLoadingSeasonStats}
                        playerTier={playerTier}
                    />
                </CardContent>
            </Collapse>
            <CardActions
                post={post}
                expanded={expanded}
                setExpanded={setExpanded}
                isMobile={isMobile}
                hasCollapsedContent={hasOverflowContent || !!(offense || defense || physical)}
            />
            {commentSection}
        </Card>
    )
}

export default ScoutingPost
