import type { PostProps } from '../Post'
import React, { useMemo, useState } from 'react'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import Collapse from '@mui/material/Collapse'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Grid'
import { westchesterSlug } from '../../../shared/utils/leagues'
import { handleTouchTap } from '../../form/formUtils'
import FormattedText from '../FormattedText'
import UncollapsedCardContent from '../UncollapsedCardContent'
import CardActions from '../CardActions'
import PostTags from '../PostTags'
import PinPostIcon from '../PinPostIcon'
import GameContent from './GameContent'
import GameLeaderSection from './GameLeaderSection'
import { useBreakPoints, useGameBoxScore } from '@/lib/hooks'
import GameDetailsHeader from '@/components/games/GameDetailsHeader'
import TeamLogo from '@/components/stats/StatsTeamLogo'

type GamePostProps = PostProps<DTO.GameReport> & { teamLogoUrl: string | null | undefined }

const GamePost = ({
    post,
    editPostSection,
    commentSection,
    expanded,
    setExpanded,
    mobileTagLimit,
    tabletTagLimit,
    desktopTagLimit,
    maxCardContentHeight,
    canEditPost,
    teamLogoUrl,
    isPinned = false,
}: GamePostProps): JSX.Element => {
    const [hasOverflowContent, setHasOverflowContent] = useState(false)
    const { isMobile, isTablet } = useBreakPoints()

    const { tags, pregame, postgame, playerTexts, game, team, teamStandings } = post
    const isGameFinished = game?.status === 'FINISHED'
    const { data, isLoading } = useGameBoxScore(post.gameId, { enabled: isGameFinished })

    const gameBoxscore = data || []
    const isBoxscoreLoading = isGameFinished && isLoading

    const adjustedTeamStandings = useMemo(() => {
        if (!teamStandings) return null

        if (post.game?.status === 'FINISHED') {
            const isAwayTeamWinner = post.game.winningTeamId === post.game.awayTeamId
            const isHomeTeamWinner = post.game.winningTeamId === post.game.homeTeamId

            return {
                awayTeamLosses: teamStandings.awayTeamLosses + (isAwayTeamWinner ? 0 : 1),
                awayTeamWins: teamStandings.awayTeamWins + (isAwayTeamWinner ? 1 : 0),
                homeTeamLosses: teamStandings.homeTeamLosses + (isHomeTeamWinner ? 0 : 1),
                homeTeamWins: teamStandings.homeTeamWins + (isHomeTeamWinner ? 1 : 0),
            }
        }
        return teamStandings
    }, [teamStandings, post.game?.status, post.game?.winningTeamId, post.game?.awayTeamId, post.game?.homeTeamId])

    return (
        <Card
            sx={{ pageBreakInside: expanded ? 'auto' : 'avoid' }}
            id={`card-element-${post.postID}`}
            variant="outlined"
        >
            <CardHeader
                sx={{
                    paddingTop: 1,
                    paddingBottom: 0,
                    '.MuiCardHeader-avatar': { xs: { marginRight: 0 }, sm: { marginRight: '16px' } },
                }}
                avatar={
                    <Stack direction="row" spacing={1}>
                        <Chip
                            size="small"
                            label={isMobile ? '' : 'GAME'}
                            sx={{
                                color: 'common.white',
                                backgroundColor: team?.urlSlug === westchesterSlug ? 'westchester.blue' : 'knicks.blue',
                                fontWeight: 'medium',
                                display: 'flex',
                                justifyContent: 'center',
                                '.MuiChip-label': {
                                    xs: { paddingLeft: 0, paddingRight: 0 },
                                    sm: { paddingLeft: '2px', paddingRight: '8px' },
                                },
                            }}
                            avatar={<TeamLogo url={teamLogoUrl || ''} name={null} height={24} width={24} />}
                        />
                        <Stack direction="row" spacing={1}>
                            <PostTags
                                tags={tags}
                                startIndex={0}
                                endIndex={isMobile ? mobileTagLimit : isTablet ? tabletTagLimit : desktopTagLimit}
                                expanded={expanded}
                                abbreviate={isMobile || isTablet}
                            />
                        </Stack>
                    </Stack>
                }
                action={canEditPost && editPostSection}
                subheader={isPinned && <PinPostIcon />}
            />
            {expanded && (
                <Stack direction="row" sx={{ paddingX: 2, position: 'relative', flexWrap: 'wrap', gap: 1 }}>
                    <PostTags
                        tags={tags}
                        startIndex={isMobile ? mobileTagLimit : desktopTagLimit}
                        endIndex={tags.length}
                        expanded={expanded}
                        abbreviate={isMobile || isTablet}
                    />
                </Stack>
            )}
            <Divider sx={{ paddingTop: 1 }} />
            <UncollapsedCardContent
                maxCardContentHeight={maxCardContentHeight}
                expanded={expanded}
                hasOverflowContent={hasOverflowContent}
                setHasOverflowContent={setHasOverflowContent}
            >
                {game && (
                    <Box onTouchEnd={(e) => handleTouchTap(e, setExpanded, expanded)}>
                        <Grid container>
                            <Grid xs={12} item md={isGameFinished ? 6 : 8}>
                                <GameDetailsHeader
                                    event={{ ...game, date: game.gameDate }}
                                    teamStandings={adjustedTeamStandings}
                                    isFutureEvent={!isGameFinished}
                                    isLandscapeOrDesktop={false}
                                    isPostView
                                />
                            </Grid>
                            {isGameFinished && team && (!!gameBoxscore.length || isBoxscoreLoading) && (
                                <>
                                    <Grid item md display={{ xs: 'none', md: 'flex' }}>
                                        <Divider
                                            orientation="vertical"
                                            flexItem
                                            sx={{ height: 100, marginX: 'auto' }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={5.5}>
                                        <GameLeaderSection
                                            players={gameBoxscore.filter((gb) => gb.teamId === team.teamId)}
                                            isBoxscoreLoading={isBoxscoreLoading}
                                        />
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </Box>
                )}
                {!isGameFinished && pregame && (
                    <Box onTouchEnd={(e) => handleTouchTap(e, setExpanded, expanded)}>
                        <FormattedText label="Pregame Notes" text={pregame} marginBottom={false} />
                    </Box>
                )}
            </UncollapsedCardContent>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent
                    sx={{
                        paddingTop: 1,
                        paddingBottom: 0,

                        '&.MuiCardContent-root:last-child': { paddingBottom: 0 },
                    }}
                >
                    <GameContent
                        pregame={pregame}
                        postgame={postgame}
                        playerTexts={playerTexts}
                        game={game}
                        gameBoxscore={gameBoxscore}
                        team={team}
                        playerTags={tags.filter((t) => t.entityType === 'player') as DTO.PlayerTag[]}
                        isBoxscoreLoading={isBoxscoreLoading}
                    />
                </CardContent>
            </Collapse>
            <CardActions
                post={post}
                expanded={expanded}
                setExpanded={setExpanded}
                isMobile={isMobile}
                hasCollapsedContent={hasOverflowContent || !!postgame || !!playerTexts.length}
            />
            {commentSection}
        </Card>
    )
}

export default GamePost
