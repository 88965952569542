import type { EditorElement } from '../form/RichText/Editor'
import React, { useRef } from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import LoadingButton from '@mui/lab/LoadingButton'
import Button from '@mui/material/Button'
import { useFormik } from 'formik'
import * as yup from 'yup'
import RichText from '../form/RichText/Editor'
import { isSubmitButtonDisabled } from '../form/formUtils'
import { useCreateComment, useUpdateComment } from '../../lib/hooks'
import useToastContext from '../../lib/hooks/useToastContext'
import { trimRichText } from '../../lib/utils/richText'
import handleError from '../../lib/utils/handleError'

type CommentFormProps = {
    initialValues: {
        commentId?: string
        postId: string
        formattedText: string
    }
    setIsEditing?: (value: boolean) => void
}

const CommentForm = ({ initialValues, setIsEditing }: CommentFormProps): JSX.Element => {
    const { mutateAsync: post, isPending: isCreateLoading } = useCreateComment()
    const { mutateAsync: put, isPending: isUpdateLoading } = useUpdateComment()
    const toastContext = useToastContext()
    const childRef = useRef<EditorElement | null>(null)

    const validationSchema = yup.object().shape({
        postId: yup.string().required().equals([initialValues.postId]),
        formattedText: yup.string().required('Text is required'),
    })

    const formik = useFormik<Params.Comment>({
        initialValues: {
            commentId: initialValues.commentId,
            postId: initialValues.postId,
            formattedText: initialValues.formattedText,
        },
        validationSchema,
        onSubmit: (values, { setSubmitting, resetForm }) => {
            const submitValues = {
                ...values,
                formattedText: trimRichText(values.formattedText) as string,
                // formik validation will fail if text is null
            }
            if (initialValues.commentId) {
                return put(submitValues, {
                    onSuccess: () => {
                        toastContext?.addToast({
                            severity: 'success',
                            message: 'Comment Updated',
                        })
                        setIsEditing?.(false)
                    },
                    onError: (error) => handleError(error, toastContext, setSubmitting),
                })
            }
            return post(submitValues, {
                onSuccess: () => {
                    toastContext?.addToast({
                        severity: 'success',
                        message: 'Comment Created',
                    })
                    resetForm()
                    childRef.current?.clearEditorContent()
                },
                onError: (error) => handleError(error, toastContext, setSubmitting),
            })
        },
    })

    return (
        <form noValidate onSubmit={formik.handleSubmit}>
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }} marginY={2} gap={1}>
                <Box flex={1}>
                    <RichText
                        id={initialValues.commentId || initialValues.postId}
                        initialValue={formik.values.formattedText}
                        focusOnInitialization={!!formik.values.formattedText}
                        placeholder="Type Comments..."
                        label="Comment"
                        onChange={(text) => formik.setFieldValue('formattedText', text)}
                        error={formik.touched.formattedText && Boolean(formik.errors.formattedText)}
                        errorText="Text is required"
                        minHeight={65}
                        maxHeight={300}
                        ref={childRef}
                    />
                </Box>
                <Box
                    alignSelf="flex-end"
                    sx={{
                        position: 'relative',
                        bottom: formik.touched.formattedText && !!formik.errors.formattedText ? 24 : 0,
                    }}
                >
                    <Stack sx={{ flexDirection: { xs: 'row', sm: 'column' }, gap: 0.5 }}>
                        {initialValues.commentId && (
                            <Button
                                sx={{ color: 'text.secondary' }}
                                variant="text"
                                onClick={() => setIsEditing?.(false)}
                            >
                                Cancel
                            </Button>
                        )}
                        <LoadingButton
                            disabled={isSubmitButtonDisabled({
                                isSubmitting: formik.isSubmitting,
                                dirty: formik.dirty,
                                isValid: formik.isValid,
                                submitCount: formik.submitCount,
                            })}
                            loading={isCreateLoading || isUpdateLoading}
                            type="submit"
                            variant="outlined"
                        >
                            Post
                        </LoadingButton>
                    </Stack>
                </Box>
            </Box>
        </form>
    )
}

export default CommentForm
